export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL ?? '';
export const REST_URL = process.env.REACT_APP_REST_URL ?? '';
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL ?? '';
export const CDN_URL = process.env.REACT_APP_CDN_URL ?? '';
export const JIVO_ID = process.env.REACT_APP_JIVO_ID ?? '';
export const YM_ID = process.env.REACT_APP_YM_ID ? Number(process.env.REACT_APP_YM_ID) : 0;

export const DNS_DOMAIN = process.env.REACT_APP_DNS_DOMAIN ?? '';
export const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN ?? '';
export const CENTER_DOMAIN = process.env.REACT_APP_CENTER_DOMAIN ?? '';

export const TINYMCE_API_KEY = process.env.REACT_APP_TINYMCE_API_KEY ?? '';
export const YANDEX_SITE_KEY = process.env.REACT_APP_YANDEX_SITE_KEY ?? '';
