import { format, startOfDay, addDays, isBefore } from 'date-fns';

import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';
import { i18n, getLocaleForDateFns } from '@lib/i18n';
import { StringHelper } from '@helpers';
import { programTypeTranslates } from '@modules/lap/constants';
import { CourseEntity } from '@modules/courses/entities';
import { LapProgramType, Platform } from '@modules/types/graphql';
import { LapIntegrationsEntity } from './lap-integrations-entity';

import type { Lap } from '@modules/types/graphql';

export type Entity = Partial<Lap> | null;

type getFormattedNameOptions = {
    withPlatform?: boolean;
    withLeadDot?: boolean;
};

@DecribeEntity('LapEntity')
class LapEntity extends BaseEntity {
    id: string;
    name: string;
    from: string;
    to: string;
    platform: Platform | null;
    isCurrent: boolean;
    programType: LapProgramType | null;
    isFree: boolean;
    canCertificate: boolean;

    @Relation(() => CourseEntity)
    course: CourseEntity;

    @Relation(() => LapIntegrationsEntity)
    integrations: LapIntegrationsEntity;

    constructor(lap: Entity) {
        super(lap);

        this.id = lap?.id ?? '';
        this.name = lap?.name ?? '';
        this.from = lap?.from ?? '';
        this.to = lap?.to ?? '';
        this.platform = lap?.platform ?? null;
        this.isCurrent = lap?.isCurrent ?? false;
        this.programType = lap?.programType ?? null;
        this.isFree = lap?.isFree ?? false;
        this.canCertificate = lap?.canCertificate ?? false;
    }

    exists() {
        const exists = this.id !== '';

        return exists;
    }

    getProgramType() {
        if (!this.programType) {
            return null;
        }

        const programType = i18n.t(programTypeTranslates[this.programType]);

        return programType;
    }

    getPlatformTitle() {
        if (!this.platform) {
            return null;
        }

        const title = StringHelper.capitalizeFirstLetter(this.platform);

        return title;
    }

    getFullDate() {
        const fromDate = this.from ? new Date(this.from) : null;
        const toDate = this.to ? new Date(this.to) : null;

        const locale = getLocaleForDateFns();

        const from = fromDate ? format(fromDate, 'dd.MM.yyyy', { locale }) : '//';
        const to = toDate ? format(toDate, 'dd.MM.yyyy', { locale }) : '//';

        const date = `${from} - ${to}`;

        return date;
    }

    getDate(dateString: string): Date | null {
        if (!dateString) {
            return null;
        }

        const date = new Date(dateString);

        return date;
    }

    getDateRanges() {
        const fromDate = this.from ? new Date(this.from) : null;
        const toDate = this.to ? new Date(this.to) : null;

        if (!fromDate || !toDate) {
            return [];
        }

        const _format = (date: Date) =>
            format(date, `EEEEEE, dd.MM.yyyy`, {
                locale: getLocaleForDateFns(),
            });

        const toDateStartOfDay = startOfDay(toDate);

        let currentDate = startOfDay(fromDate);

        const range = [
            {
                date: format(currentDate, 'yyyy-MM-dd'),
                label: _format(currentDate),
            },
        ];

        while (isBefore(currentDate, toDateStartOfDay)) {
            currentDate = addDays(currentDate, 1);

            range.push({
                date: format(currentDate, 'yyyy-MM-dd'),
                label: _format(currentDate),
            });
        }

        return range;
    }

    nowDateBefore() {
        if (!this.from) {
            return false;
        }

        const now = new Date().getTime();
        const from = new Date(this.from).getTime();

        return now < from;
    }

    nowDateIncluded() {
        if (!this.from || !this.to) {
            return false;
        }

        const now = new Date().getTime();
        const from = new Date(this.from).getTime();
        const to = new Date(this.to).getTime();

        return from <= now && now <= to;
    }

    nowDateAfter() {
        if (!this.to) {
            return false;
        }

        const now = new Date().getTime();
        const to = new Date(this.to).getTime();

        return now > to;
    }

    hasCurrentDateInLap() {
        return this.nowDateIncluded() || this.nowDateAfter();
    }

    isActiveLap(currentLap: LapEntity) {
        if (!currentLap.exists() || !this.id) {
            return false;
        }

        const isActiveLap = currentLap.id === this.id;

        return isActiveLap;
    }

    isCenterLap() {
        const isCenterLap = this.platform === Platform.center;

        return isCenterLap;
    }

    isOnlineLap() {
        const isOnlineLap = this.platform === Platform.online;

        return isOnlineLap;
    }

    getFormattedName(options: getFormattedNameOptions = {}) {
        const { withPlatform = true, withLeadDot = true } = options;

        const platform = this.getPlatformTitle();
        const courseName = this.course.name;
        const platformTitle = this.getProgramType();

        let name = this.name;

        if (platform && withPlatform) {
            name += ` • ${platform}`;
        }

        if (courseName || platformTitle) {
            name += ` • ${courseName || platformTitle}`;
        }

        if (!withLeadDot) {
            return name.replace(/•/, '').trim();
        }

        return name.trim();
    }

    isEljur() {
        return this.integrations.eljur;
    }
}

export { LapEntity };
