import React from 'react';

import { i18n } from '@lib/i18n';
import { useLazySchoolNumbers } from '@modules/shared/hooks';

type UseSchoolNumbersFilterOptions = {
    refRegionId?: string;
    refLocationId?: string;
};

const schoolNumbersEmpty = {
    key: 0,
    value: i18n.t('common:stubs.empty_number'),
};

const useSchoolNumbersFilter = (options: UseSchoolNumbersFilterOptions) => {
    const { refRegionId, refLocationId } = options;

    const [getSchoolNumbers, { schoolNumbers, loading: schoolNumbersLoading }] =
        useLazySchoolNumbers({
            fetchPolicy: 'network-only',
        });

    const schoolNumbersList = schoolNumbers.map(number => ({ key: number, value: String(number) }));
    const schoolNumbersOptions = [schoolNumbersEmpty, ...schoolNumbersList];

    React.useEffect(() => {
        getSchoolNumbers({
            variables: {
                regionId: refRegionId,
                locationId: refLocationId,
            },
        });
    }, [refRegionId, refLocationId]);

    return {
        schoolNumbers,
        schoolNumbersOptions,
        schoolNumbersLoading,
    };
};

export { useSchoolNumbersFilter };
