import _intersection from 'lodash/intersection';

import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';
import { i18n } from '@lib/i18n';
import { StringHelper } from '@helpers';
import { FileInfoEntity } from '@modules/shared/entities';
import { StudentEntity } from '@modules/students/entities';
import { SchoolEntity } from '@modules/schools/entities';
import { roleTranslates } from '@modules/user/constants';
import { UserRole, UserGrantCategoryToken, UserGrantActionToken } from '@modules/types/graphql';
import { LapEntity } from '@modules/lap/entities';

import type { StudentEntityType } from '@modules/students/entities';
import type { SchoolEntityType } from '@modules/schools/entities';
import type { User } from '@modules/types/graphql';

export type Entity =
    | (Partial<Omit<User, 'student' | 'school'>> & {
          student?: StudentEntityType | null;
          school?: SchoolEntityType | null;
      })
    | null;

type CanGrantsCategoryKey<Category extends string> = Category extends `${infer Head}_${infer Tail}`
    ? `${Capitalize<Head>}${CanGrantsCategoryKey<Tail>}`
    : Capitalize<Category>;

type CanGrantsActionKey<Action extends string> = Action extends `${infer Head}_${infer Tail}`
    ? `${Capitalize<Head>}${CanGrantsActionKey<Tail>}`
    : `${Capitalize<Action>}`;

type CanGrantsPayload<
    Category extends UserGrantCategoryToken,
    Actions extends UserGrantActionToken[],
> = Record<Category, Actions>;

type CanGrants<
    Category extends UserGrantCategoryToken,
    Actions extends UserGrantActionToken[],
> = Record<
    `${Uncapitalize<CanGrantsCategoryKey<Category>>}Grants`,
    { [Key in keyof Actions as `can${CanGrantsActionKey<Actions[number]>}`]: boolean } & {
        canSelectedActions: boolean;
    }
>;

@DecribeEntity('UserEntity')
class UserEntity extends BaseEntity {
    id: string;
    username: string;
    email: string;
    fullName: string;
    roles: (UserRole | null)[];
    grants: Record<UserGrantCategoryToken, UserGrantActionToken[]>;
    photoId: string;

    @Relation(() => FileInfoEntity)
    photo: FileInfoEntity;

    @Relation(() => StudentEntity)
    student: StudentEntity;

    @Relation(() => SchoolEntity)
    school: SchoolEntity;

    constructor(entity: Entity) {
        super(entity);

        this.id = entity?.id ?? '';
        this.username = entity?.username ?? '';
        this.email = entity?.email ?? '';
        this.fullName = entity?.fullName ?? '';
        this.roles = entity?.roles ?? [];
        this.photoId = entity?.photoId ?? '';

        this.grants = (entity?.grants ?? []).reduce((carry, grant) => {
            if (!grant || !grant.category) {
                return carry;
            }

            if (typeof carry[grant.category] === 'undefined') {
                carry[grant.category] = (grant.actions ?? []) as UserGrantActionToken[];
            }

            return carry;
        }, {} as Record<UserGrantCategoryToken, UserGrantActionToken[]>);
    }

    // TODO: need to move to grants service
    can<C extends UserGrantCategoryToken, A extends UserGrantActionToken[]>(
        payload: CanGrantsPayload<C, A>,
    ): CanGrants<C, A> {
        let result = {} as CanGrants<C, A>;

        for (let category in payload) {
            const categoryActions = this.grants[category] ?? [];
            const actions = payload[category];

            let canActions = _intersection(categoryActions, actions);
            if (categoryActions.length === 1 && categoryActions[0] === UserGrantActionToken.all) {
                canActions = actions;
            }

            const camelCaseCategory = category
                .split('_')
                .map((part, idx) => (idx === 0 ? part : StringHelper.capitalizeFirstLetter(part)))
                .join('');

            result[camelCaseCategory + 'Grants'] = actions.reduce(
                (carry, action) => {
                    const camelCaseAction = action
                        .split('_')
                        .map(StringHelper.capitalizeFirstLetter)
                        .join('');

                    const hasAllGrants =
                        categoryActions.length === 1 &&
                        categoryActions[0] === UserGrantActionToken.all;

                    const hasGrant = canActions.includes(action);

                    if (hasAllGrants) {
                        carry['can' + camelCaseAction] = true;
                        carry.canSelectedActions = true;
                    } else {
                        carry['can' + camelCaseAction] = hasGrant;

                        if (!hasGrant) {
                            carry.canSelectedActions = false;
                        }
                    }

                    return carry;
                },
                { canSelectedActions: true },
            );
        }

        return result;
    }

    canDownloadCertificates(lap: LapEntity): boolean {
        return this.isSchool() && lap.canCertificate;
    }

    getAllowedRoutes() {
        const categories = Object.keys(this.grants) as UserGrantCategoryToken[];

        const hasAllGrants = (actions: UserGrantActionToken[]) =>
            actions.length === 1 && actions[0] === UserGrantActionToken.all;

        const result = categories.filter(category => {
            const actions = this.grants[category];

            if (hasAllGrants(actions)) {
                return true;
            }

            const result = hasAllGrants(actions)
                ? true
                : actions.includes(UserGrantActionToken.view);

            return result;
        });

        return result;
    }

    hasAccessByGrants(category?: UserGrantCategoryToken) {
        if (!category) {
            return true;
        }

        const actions = this.grants[category];
        const hasAllGrants = actions.length === 1 && actions[0] === UserGrantActionToken.all;

        return hasAllGrants ? true : actions.includes(UserGrantActionToken.view);
    }

    getFullNameByRole() {
        if (this.isStudent()) {
            return this.student.fullName;
        }

        if (this.isSchool()) {
            return this.school.shortName;
        }

        return this.fullName;
    }

    getFullNameWithRole() {
        const role = this.getRole().toLowerCase();
        const fullName = this.getFullNameByRole();

        return `${role} ${fullName}`;
    }

    getRole() {
        if (this.roles.length === 0) {
            return '-';
        }

        if (this.roles && this.roles[0]) {
            const role = this.roles[0];

            return i18n.t(roleTranslates[role]);
        }

        return this.roles.toString();
    }

    getCurrentRole() {
        const role = this.roles?.[0];

        return role;
    }

    authenticated() {
        const isAuth = this.id !== '';

        return isAuth;
    }

    isSuperAdmin() {
        const isSuperAdmin = this.roles.includes(UserRole.superAdmin);

        return isSuperAdmin;
    }

    isAdmin() {
        const isAdmin = this.roles.includes(UserRole.admin);

        return isAdmin;
    }

    isSchool() {
        const isSchool = this.roles.includes(UserRole.school);

        return isSchool;
    }

    isInstructor() {
        const isInstructor = this.roles.includes(UserRole.instructor);

        return isInstructor;
    }

    isLeader() {
        const isLeader = this.roles.includes(UserRole.leader);

        return isLeader;
    }

    isMentor() {
        const isMentor = this.roles.includes(UserRole.mentor);

        return isMentor;
    }

    isMethodist() {
        const isMethodist = this.roles.includes(UserRole.methodist);

        return isMethodist;
    }

    isRegistrar() {
        const isRegistrar = this.roles.includes(UserRole.registrar);

        return isRegistrar;
    }

    isStudent() {
        const isStudent = this.roles.includes(UserRole.student);

        return isStudent;
    }

    isEmployee() {
        const roles = [
            UserRole.registrar,
            UserRole.instructor,
            UserRole.methodist,
            UserRole.leader,
            UserRole.seniorInstructor,
            UserRole.mentor,
            UserRole.juniorLeader,
            UserRole.psychologist,
            UserRole.organizingTeacher,
        ];

        return this.hasAccessByRoles(roles);
    }

    hasAccessByRoles(roles?: UserRole[]) {
        if (!roles) {
            return true;
        }

        const hasAccess = _intersection(this.roles, roles).length !== 0;

        return hasAccess;
    }

    isAnonymously() {
        const isAnon = this.fullName.includes('anon');

        return isAnon;
    }
}

export { UserEntity };
