import React from 'react';
import _noop from 'lodash/noop';

import { useEvent } from '@modules/shared/hooks';
import { getAutocompleteIdByElement } from './helpers';
import { useInternalPageFilters } from './use-internal-page-filters';

import type { AutocompleteChangeReason } from '@material-ui/lab';
import type { Filters, PageFiltersOptions, PageFiltersResult } from './types';

type ChangeAutocompleteOptions = {
    multiple?: boolean;
    reset?: string[];
    extractSingleBy?: string;
    extractMultiplyBy?: string;
};

type PageFiltersHelpers = {
    /**
     * default by = id
     */
    getOptionValue: <R>(references: R[], value?: any | null, by?: string) => R | null;

    /**
     * default by = key
     */
    getMultipleOptionValue: (options: (any | null)[], values?: any[], by?: string) => any[];
    getOptionLabel: (field: string) => (option: any | null) => string;
    /**
     * default by = id
     */
    getOptionSelected: (by?: string) => (option: any | null, value: any | null) => boolean;
    /**
     * default options
     * multiple = false
     * extractSingleBy = 'id'
     * extractMultiplyBy = 'key'
     */
    handleChangeAutocomplete: (
        options?: ChangeAutocompleteOptions,
    ) => (
        event: React.ChangeEvent<any>,
        value: any | null,
        reason: AutocompleteChangeReason,
    ) => void;
};

type PageFiltersContextValue<T> = PageFiltersResult<T> & PageFiltersHelpers;

const DEFAULT_VALUE = {
    issetFilters: false,
    drawerFiltersCount: 0,
    filters: {},
    handleChange: _noop,
    handleSubmit: _noop,
    setFilters: _noop,
    setFilterValue: _noop,
    resetFilters: _noop,

    getOptionValue: _noop as PageFiltersHelpers['getOptionValue'],
    getMultipleOptionValue: _noop as PageFiltersHelpers['getMultipleOptionValue'],
    getOptionLabel: _noop as PageFiltersHelpers['getOptionLabel'],
    getOptionSelected: _noop as PageFiltersHelpers['getOptionSelected'],
    handleChangeAutocomplete: _noop as PageFiltersHelpers['handleChangeAutocomplete'],
};

const PageFiltersContext = React.createContext<PageFiltersContextValue<any>>(DEFAULT_VALUE);

type PageFiltersProviderProps<T extends Filters> = PageFiltersOptions<T> & {
    children: React.ReactNode;
};

const PageFiltersProvider = <T extends Filters>(props: PageFiltersProviderProps<T>) => {
    const { children, ...otherProps } = props;

    const {
        issetFilters,
        filters,
        drawerFiltersCount,
        setFilterValue,
        setFilters,
        resetFilters,
        handleChange,
        handleSubmit,
    } = useInternalPageFilters<T>(otherProps);

    const getOptionValue: PageFiltersHelpers['getOptionValue'] = useEvent(
        (references, value, by = 'id') => {
            const idx = references.findIndex(reference => {
                if (!reference) {
                    return false;
                }

                if (by.endsWith('()')) {
                    const func = by.replace('()', '');

                    return reference[func]() === value;
                }

                if (by) {
                    return reference[by] === value;
                }

                return reference === value;
            });

            if (idx > -1) {
                return references[idx];
            }

            return null;
        },
    );

    const getMultipleOptionValue: PageFiltersHelpers['getMultipleOptionValue'] = useEvent(
        (options, values, by = 'key') => {
            const items = options.filter(
                option => values && !!option && values.includes(option[by]),
            );

            return items;
        },
    );

    const getOptionLabel: PageFiltersHelpers['getOptionLabel'] = useEvent(field => option => {
        if (!option) {
            return '-';
        }

        if (field.endsWith('()')) {
            const func = field.replace('()', '');

            return option[func]?.();
        }

        return option[field] || '-';
    });

    const getOptionSelected: PageFiltersHelpers['getOptionSelected'] = useEvent(
        (by = 'id') =>
            (option: any | null, value: any | null): boolean => {
                if (!option || !value) {
                    return false;
                }

                if (by) {
                    return option[by] === value[by];
                }

                return option === value;
            },
    );

    const handleChangeAutocomplete: PageFiltersHelpers['handleChangeAutocomplete'] = useEvent(
        (options = {}) => {
            const {
                multiple = false,
                reset,
                extractSingleBy = 'id',
                extractMultiplyBy = 'key',
            } = options;

            return (event, value, reason) => {
                let id = '';

                if (reason === 'remove-option' || reason === 'clear') {
                    id = getAutocompleteIdByElement(event.target);
                } else {
                    [id] = event.target.id.split('-');
                }

                if (multiple) {
                    setFilterValue(
                        id,
                        (value as any[])?.map(item => item?.[extractMultiplyBy]).filter(Boolean),
                    );
                } else {
                    if (extractSingleBy.endsWith('()')) {
                        const func = extractSingleBy.replace('()', '');

                        setFilterValue(id, value[func]?.());
                    } else {
                        if (extractSingleBy) {
                            setFilterValue(id, value?.[extractSingleBy]);
                        } else {
                            setFilterValue(id, value);
                        }
                    }
                }

                if (reset) {
                    for (const key of reset) {
                        setFilterValue(key, '');
                    }
                }
            };
        },
    );

    const ctx = {
        issetFilters,
        drawerFiltersCount,
        filters,
        setFilterValue,
        setFilters,
        resetFilters,
        handleChange,
        handleSubmit,

        getOptionValue,
        getMultipleOptionValue,
        getOptionLabel,
        getOptionSelected,
        handleChangeAutocomplete,
    };

    return <PageFiltersContext.Provider value={ctx}>{children}</PageFiltersContext.Provider>;
};

const usePageFilters = <T extends any>(): PageFiltersContextValue<T> => {
    const context = React.useContext(PageFiltersContext);

    if (context === undefined) {
        throw new Error('page filters context value are undefined');
    }

    return context;
};

export { PageFiltersProvider, usePageFilters };
